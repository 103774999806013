import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../config/typography"

const Layout = ({ location, site, children }) => {
  const title = site.title
  const description = site.description
  const rootPath = `${__PATH_PREFIX__}/`
  const frontPage = location.pathname === rootPath

  const blogTitleFont = "Arial"
  const blogMetaFont = "Open Sans"

  let header

  if (frontPage) {
    header = (
      <div>
        <h1
          style={{
            ...scale(1),
            fontFamily: `${blogTitleFont}`,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
        <p
          style={{
            ...scale(1),
            fontFamily: `${blogMetaFont}`,
            fontSize: "1.1em",
            fontStyle: "italic",
            marginTop: "-1em",
            marginBottom: "-1.5em",
          }}
        >
          {description}
        </p>
      </div>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `${blogTitleFont}`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(30),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer
        style={{
          fontFamily: `${blogMetaFont}`,
          fontSize: `0.8rem`,
          marginTop: rhythm(1),
        }}
      >
        © {new Date().getFullYear()}
        {` `} <a href="https://github.com/GPAzevedo">Gustavo Azevedo</a>
        {` & `} <a href="https://github.com/RPAzevedo">Rafael Azevedo</a>
      </footer>
    </div>
  )
}

export default Layout
